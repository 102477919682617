<template>
    <div class="pageContainer keshiReportPage flexCloumn">
        <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
            <div class="flexStart flex_1" style="flex-wrap:wrap;">

                <tchtree v-model="searchForm.class_id"></tchtree>
                <el-input placeholder="学生姓名" v-model="searchForm.keyword" size="small" class="input-with-select"
                    style="width:200px" clearable>
                </el-input>
                <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
                    @click="() => { page.current_page = 1; getList() }">
                    搜索
                </el-button>
            </div>
        </el-row>
        <div class="dividerBar"></div>
        <el-table :data="DataList" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
            <el-table-column type="index" width="65" label="序号" align="center"></el-table-column>
            <el-table-column prop="stu_code" label="学号" width="135" align="center"></el-table-column>
            <el-table-column prop="stu_name" label="学生姓名" width="120" align="center"></el-table-column>
            <el-table-column prop="class_name" label="所在班级" width="120" align="center"></el-table-column>
            <el-table-column label="部门" prop="create_date" min-width="200" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.department }}{{ scope.row.deng ? '等' : '' }}
                </template>
            </el-table-column>
            <el-table-column label="备注" prop="create_date" width="200" align="center">
                <template slot-scope="scope">
                    <div class="flexCenter"> 共计 <span style="font-size:16px;color: #33a9ff;margin:0 3px"> {{ scope.row.count
                    }} </span>篇
                    </div>
                </template>
            </el-table-column>

            <el-table-column fixed="right" label="操作" width="90" align="center">
                <template slot-scope="scope">
                    <el-tag class="pointer" type="" size="mini" @click="viewInfo(scope.row, 0)">查看详情</el-tag>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
            :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
            :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
        </el-pagination>

        <!-- 科室列表 -->
        <el-dialog class="cus_dialog" :visible.sync="showKeshiList" :close-on-click-modal="false" custom-class="cus_dialog"
            width="1000px">
            <div slot="title" class="flexBetween">
                <span style="color: #ffffff;"> <span style="font-weight: 700;">{{ EditItem.stu_name }}</span> 的科室实习手册</span>
            </div>
            <div class="flexCloumn" style="width:100%;height:100%;">
                <div class="flex_1" style="width:100%;height:calc(100% - 40px);">
                    <el-table height="100%" :data="keshiList" size="small" stripe border
                        style="width:fit-content;width:100%;">
                        <el-table-column type="index" label="序号" width="60px" align="center"></el-table-column>
                        <el-table-column label="实习单位" prop="firm_name" width="160px" show-overflow-tooltip
                            align="center"></el-table-column>
                        <el-table-column label="实习科室" prop="department" show-overflow-tooltip width="135"
                            align="center"></el-table-column>
                        <el-table-column label="实习科目" prop="subject" show-overflow-tooltip width="135" align="center">
                        </el-table-column>
                        <el-table-column label="实习时间" prop="start_time" width="140px">
                            <template slot-scope="scope">
                                <div>{{ scope.row.start_time }} ~</div>
                                <div>{{ scope.row.start_time }}</div>
                            </template>
                        </el-table-column>

                        <el-table-column label="填写情况" prop="remark" min-width="150px" align="">
                            <template slot-scope="scope">
                                <div>
                                    基本信息：<span style="color:transparent;">补</span><el-tag type="success"
                                        size="mini">已填写</el-tag>
                                </div>
                                <div>
                                    自我小结：<span style="color:transparent;">补</span><el-tag v-if="scope.row.content"
                                        type="success" size="mini">已填写</el-tag>
                                    <el-tag v-else type="danger" size="mini">未填写</el-tag>
                                </div>
                                <div>
                                    实习评测表：<el-tag v-if="scope.row.evaluation" type="success" size="mini">已填写</el-tag>
                                    <el-tag v-else type="danger" size="mini">未填写</el-tag>
                                </div>
                            </template>
                        </el-table-column>



                        <el-table-column fixed="right" label="操作" width="90" align="center">
                            <template slot-scope="scope">
                                <el-tag class="pointer" type="" size="mini" @click="viewInfo(scope.row, 1)">查看详情</el-tag>
                                <!-- <el-tag class="pointer" type="danger" size="mini" style="margin-left: 10px;"
                                    @click="handleDelete(scope.row)">删除
                                </el-tag> -->
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <el-pagination @current-change="handlePageChange_keshi" @size-change="handleSizeChange_keshi"
                    :current-page="page_keshi.current_page" :page-size="page_keshi.per_page" :total="page_keshi.count"
                    layout="total, sizes,prev, pager, next" :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
                </el-pagination>
            </div>
        </el-dialog>
        <!-- 科室实习手册详情 -->
        <el-dialog :visible.sync="dialogshow" :close-on-click-modal="false" :modal-append-to-body="false"
            :destroy-on-close="true" custom-class="cus_dialog cus_dialogMax" width="900px">
            <div slot="title" class="flexBetween">
                <span style="color: #ffffff;"> <span style="font-weight: 700;">{{ detailInfo.stu_name }}</span>
                    {{ detailInfo.firm_name || '' }} {{ detailInfo.department || '' }} {{ detailInfo.subject || '' }}
                    科室实习手册</span>
            </div>
            <el-tabs class="tabContainer" v-model="activeName" type="" @tab-click="handleClick">
                <el-tab-pane label="基本信息" name="first"></el-tab-pane>
                <el-tab-pane label="自我小结" name="second"></el-tab-pane>
                <el-tab-pane label="实习评测表" name="third"></el-tab-pane>
                <el-tab-pane label="实习鉴定" name="fouth"></el-tab-pane>
            </el-tabs>
            <div v-if="activeName == 'first'" class="dialog_left flexCloumn" style="width:100%;height:calc(100% - 41px);">
                <div class="" style="width: 100%;height: 100%;overflow-y:auto;">
                    <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
                        <span></span>
                        <span style="font-weight: 400">实习基本信息</span>
                    </div>
                    <div class="flexStart"
                        style="margin:10px 0;flex-wrap: wrap;width: calc( 100% - 30px );margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">实习单位</div>
                            <div class="cell flexStart flex_1" style="padding:0 3px;">{{ detailInfo.firm_name || '-' }}
                            </div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">实习科室</div>
                            <div class="cell flexStart flex_1" style="padding:0 3px;">{{ detailInfo.department || '-' }}
                            </div>
                        </div>
                        <div class="lineRow flexStretch" style="width:100%;">
                            <div class="cell flexStart">实习科目</div>
                            <div class="cell flexStart flex_1" style="padding:0 3px;">{{ detailInfo.subject || '-' }}</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">实习开始时间</div>
                            <div class="cell flexStart flex_1" style="padding:0 3px;">{{ detailInfo.start_time || '-' }}
                            </div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">实习结束时间</div>
                            <div class="cell flexStart flex_1" style="padding:0 3px;">{{ detailInfo.end_time || '-' }}</div>
                        </div>
                    </div>
                    <div class="cellGroupBarTitle flexStart">
                        <span></span>
                        <span style="font-weight: 400">出勤情况</span>
                    </div>
                    <div class="flexStart"
                        style="margin:10px 0;flex-wrap: wrap;width: calc( 100% - 30px );margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">

                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">病假</div>
                            <div class="cell flexStart flex_1" style="padding:0 3px;">{{ EditItem.sick_leave || '0' }}天
                            </div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">事假</div>
                            <div class="cell flexStart flex_1" style="padding:0 3px;">{{ EditItem.personal_leave || '0' }}天
                            </div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">旷工</div>
                            <div class="cell flexStart flex_1" style="padding:0 3px;">{{ EditItem.absenteeism || '0' }}天
                            </div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">迟到</div>
                            <div class="cell flexStart flex_1" style="padding:0 3px;">{{ EditItem.be_late || '0' }}天</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:100%;">
                            <div class="cell flexStart">早退</div>
                            <div class="cell flexStart flex_1" style="padding:0 3px;">{{ EditItem.leave_early || '0' }}天
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="activeName == 'second'" class="dialog_left flexCloumn" style="width:100%;height:calc(100% - 41px);">
                <div class="" style="width: 100%;height: 100%;overflow-y:auto;">
                    <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
                        <span></span>
                        <span style="font-weight: 400">实习自我小结</span>
                    </div>
                    <div class="flexStart"
                        style="margin:10px 0;flex-wrap: wrap;width: calc( 100% - 30px );margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                        <div class="lineRow flexCloumn" style="width:100%;">
                            <div class="cell flexStart" style="min-height: 40px;height: unset;width:100%;">
                                自我小结
                            </div>
                            <div class="cell flexCloumn" style="height: unset;min-height: 40px;">
                                <div style="width:100%;">
                                    <div class=" flexCloumn">
                                        <div class=" flexStart"
                                            style="min-height: 40px;height: unset;color:#606266;padding:3px;">
                                            {{ detailInfo.content || '-' }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="lineRow flexStretch" style="width:100%;">
                            <div class="cell flexStart" style="min-height: 40px;height: unset;">签名</div>
                            <div class="cell flexStart flex_1"
                                style="height: unset;min-height: 40px;flex-wrap:wrap;padding-top:8px;">
                                <div class="flexCenter"
                                    style="position:relative;height:50px;width:100px;border:1px dashed #c0ccda;border-radius:6px;margin-right:8px;margin-bottom:8px;">
                                    <el-image style="max-height:100%;max-width:100%;" :src="detailInfo.sign"
                                        :preview-src-list="[detailInfo.sign]" img="cover" :z-index="3000"></el-image>
                                </div>
                                <div v-if="!detailInfo.sign || detailInfo.sign == 0" class="" style="width:100%;">-</div>
                            </div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">时间</div>
                            <div class="cell flexStart flex_1" style="padding:0 3px;">{{ detailInfo.sign_date || '-' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="activeName == 'third'" class="dialog_left flexCloumn" style="width:100%;height:calc(100% - 41px);">
                <div class="tabThird" style="width: 100%;height: 100%;overflow-y:auto;">
                    <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
                        <span></span>
                        <span style="font-weight: 400">医德医风测评表</span>
                        <span style="color:#909399;">
                            （ 好=10分&nbsp;&nbsp;&nbsp;较好=8分&nbsp;&nbsp;&nbsp;一般=6分&nbsp;&nbsp;&nbsp;差=0分 ）
                        </span>
                    </div>
                    <div class="flexStart"
                        style="margin:10px 0;flex-wrap: wrap;width: calc( 100% - 30px );margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">热爱专业虚心好学</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.yd1 || 0 }}分</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">尊重病人一视同仁</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.yd2 || 0 }}分</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">工作负责服务周到</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.yd3 || 0 }}分</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">语言文明礼貌待人</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.yd4 || 0 }}分</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">尊敬老师服从安排</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.yd5 || 0 }}分</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">顾全大局团结协作</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.yd6 || 0 }}分</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">遵守纪律坚守岗位</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.yd7 || 0 }}分</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">诚实严谨保守医秘</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.yd8 || 0 }}分</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">着装规范仪表端庄</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.yd9 || 0 }}分</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">廉洁奉公不谋私利</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.yd10 || 0 }}分</div>
                        </div>
                    </div>
                    <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
                        <span></span>
                        <span style="font-weight: 400;white-space:nowrap;">扣除分</span>
                        <span style="color:#909399;">
                            （ 若实习期间发生事故，乱开病假条、向病人索要礼物、旷工、应按每发生一项在累计分中扣除10分 ）
                        </span>
                    </div>
                    <div class="flexStart"
                        style="margin:10px 0;flex-wrap: wrap;width: calc( 100% - 30px );margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                        <div class="lineRow flexStretch" style="width:100%;">
                            <div class="cell flexStart">扣除分</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.kcScore || 0 }}分</div>
                        </div>
                    </div>
                    <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
                        <span></span>
                        <span style="font-weight: 400">临床实践能力测评表</span>
                        <span style="color:#909399;">
                            （ 好=10分&nbsp;&nbsp;&nbsp;较好=8分&nbsp;&nbsp;&nbsp;一般=6分&nbsp;&nbsp;&nbsp;差=0分 ）
                        </span>
                    </div>
                    <div class="flexStart"
                        style="margin:10px 0;flex-wrap: wrap;width: calc( 100% - 30px );margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">医疗文件书写</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.lc1 || 0 }}分</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">诊断（护理）能力</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.lc2 || 0 }}分</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">观察病情能力</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.lc3 || 0 }}分</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">处理问题能力</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.lc4 || 0 }}分</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">病房管理能力</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.lc5 || 0 }}分</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">规范操作能力</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.lc6 || 0 }}分</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">无菌操作技术</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.lc7 || 0 }}分</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">理论联系实际</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.lc8 || 0 }}分</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">完成任务情况</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.lc9 || 0 }}分</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">独立工作能力</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.lc10 || 0 }}分</div>
                        </div>
                    </div>
                    <div class="cellGroupBarTitle flexStart">
                        <span></span>
                        <span style="font-weight: 400">临床技能考核成绩表</span>
                    </div>

                    <div style="margin:10px 0;font-size:14px;color:#909399;">项目1</div>
                    <div class="flexStretch"
                        style="margin:0px 0 10px 0 ;flex-wrap: wrap;width: calc( 100% - 00px );margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                        <div class="lineRow flexStretch" style="width:50%">
                            <div class="cell flexStart">项目名称</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.xmname1 || '' }}</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">项目得分</div>
                            <div class="cell flexStart flex_1">
                                {{ evaluationInfo.xmscore1 || '' }}{{ evaluationInfo.xmscore1 ? '分' : '' }}</div>
                        </div>
                    </div>
                    <div style="margin:10px 0;font-size:14px;color:#909399;">项目2</div>
                    <div class="flexStretch"
                        style="margin:0px 0 10px 0 ;flex-wrap: wrap;width: calc( 100% - 00px );margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                        <div class="lineRow flexStretch" style="width:50%">
                            <div class="cell flexStart">项目名称</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.xmname2 || '' }}</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">项目得分</div>
                            <div class="cell flexStart flex_1">
                                {{ evaluationInfo.xmscore2 || '' }}{{ evaluationInfo.xmscore2 ? '分' : '' }}</div>
                        </div>
                    </div>
                    <div style="margin:10px 0;font-size:14px;color:#909399;">项目3</div>
                    <div class="flexStretch"
                        style="margin:0px 0 10px 0 ;flex-wrap: wrap;width: calc( 100% - 00px );margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                        <div class="lineRow flexStretch" style="width:50%">
                            <div class="cell flexStart">项目名称</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.xmname3 || '' }}</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">项目得分</div>
                            <div class="cell flexStart flex_1">
                                {{ evaluationInfo.xmscore3 || '' }}{{ evaluationInfo.xmscore3 ? '分' : '' }}</div>
                        </div>
                    </div>

                    <div class="cellGroupBarTitle flexStart">
                        <span></span>
                        <span style="font-weight: 400">完整住院病历考核成绩表</span>
                    </div>
                    <div class="flexStretch"
                        style="margin:0px 0 10px 0 ;flex-wrap: wrap;width: calc( 100% - 00px );margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                        <div class="lineRow flexStretch" style="width:50%">
                            <div class="cell flexStart">病历名称</div>
                            <div class="cell flexStart flex_1">{{ evaluationInfo.blname || '' }}</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">病历得分</div>
                            <div class="cell flexStart flex_1">
                                {{ evaluationInfo.blscore || '' }}{{ evaluationInfo.blscore ? '分' : '' }}</div>
                        </div>
                    </div>
                    <div class="cellGroupBarTitle flexStart">
                        <span></span>
                        <span style="font-weight: 400">备注栏</span>
                    </div>

                    <div class="flexStart"
                        style="margin:10px 0;flex-wrap: wrap;width: calc( 100% - 30px );margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                        <div class="lineRow flexCloumn" style="width:100%;">
                            <div class="cell flexStart" style="min-height: 40px;height: unset;width:100%;">
                                备注
                            </div>
                            <div class="cell flexCloumn" style="height: unset;min-height: 40px;">
                                <div style="width:100%;">
                                    <div class=" flexCloumn">
                                        <div class=" flexStart"
                                            style="min-height: 40px;height: unset;color:#606266;padding:3px;">
                                            {{ evaluationInfo.remark || '-' }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="cellGroupBarTitle flexStart">
                        <span></span>
                        <span style="font-weight: 400">签名</span>
                    </div>
                    <div class="flexStart"
                        style="margin:10px 0;flex-wrap: wrap;width: calc( 100% - 30px );margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">

                        <div class="lineRow flexStretch" style="width:100%;">
                            <div class="cell flexStart" style="min-height: 40px;height: unset;">带教老师签名</div>
                            <div class="cell flexStart flex_1"
                                style="height: unset;min-height: 40px;flex-wrap:wrap;padding-top:8px;">
                                <div class="flexCenter"
                                    style="position:relative;height:50px;width:100px;border:1px dashed #c0ccda;border-radius:6px;margin-right:8px;margin-bottom:8px;">
                                    <el-image style="max-height:100%;max-width:100%;" :src="evaluationInfo.sign1"
                                        :preview-src-list="[evaluationInfo.sign1]" img="cover" :z-index="3000"></el-image>
                                </div>
                                <div v-if="!evaluationInfo.sign1 || evaluationInfo.sign1 == 0" class="" style="width:100%;">
                                    -</div>
                            </div>
                        </div>


                        <div class="lineRow flexStretch" style="width:100%;">
                            <div class="cell flexStart" style="min-height: 40px;height: unset;">科主任（护士长）签名</div>
                            <div class="cell flexStart flex_1"
                                style="height: unset;min-height: 40px;flex-wrap:wrap;padding-top:8px;">
                                <div class="flexCenter"
                                    style="position:relative;height:50px;width:100px;border:1px dashed #c0ccda;border-radius:6px;margin-right:8px;margin-bottom:8px;">
                                    <el-image style="max-height:100%;max-width:100%;" :src="evaluationInfo.sign2"
                                        :preview-src-list="[evaluationInfo.sign2]" img="cover" :z-index="3000"></el-image>
                                </div>
                                <div v-if="!evaluationInfo.sign2 || evaluationInfo.sign2 == 0" class="" style="width:100%;">
                                    -</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="activeName == 'fouth'" class="dialog_left flexCloumn" style="width:100%;height:calc(100% - 41px);">
                <div class="" style="width: 100%;height: 100%;overflow-y:auto;">
                    <div v-for="(item, index) in jdList">
                        <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
                            <span></span>
                            <span style="font-weight: 400">{{ item.title }}</span>
                        </div>
                        <div class="flexStart"
                            style="margin:10px 0;flex-wrap: wrap;width: calc( 100% - 30px );margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                            <div class="lineRow flexCloumn" style="width:100%;">
                                <div class="cell flexStart" style="min-height: 40px;height: unset;width:100%;">
                                    {{ item.textareaTitle }}
                                </div>
                                <div class="cell flexCloumn" style="height: unset;min-height: 40px;">
                                    <div style="width:100%;">
                                        <div class=" flexCloumn">
                                            <div class="flexStart"
                                                style="min-height: 40px;height: unset;color:#606266;padding:3px;">
                                                {{ item.info.content || '-' }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="lineRow flexStretch" style="width:100%;">
                                <div class="cell flexStart" style="min-height: 40px;height: unset;">{{ item.signTitle }}
                                </div>
                                <div class="cell flexStart flex_1"
                                    style="height: unset;min-height: 40px;flex-wrap:wrap;padding-top:8px;">
                                    <div class="flexCenter"
                                        style="position:relative;height:50px;width:100px;border:1px dashed #c0ccda;border-radius:6px;margin-right:8px;margin-bottom:8px;">
                                        <el-image style="max-height:100%;max-width:100%;" :src="item.info.sign"
                                            :preview-src-list="[item.info.sign]" img="cover" :z-index="3000"></el-image>
                                    </div>
                                    <div v-if="!item.info.sign || item.info.sign == 0" class="" style="width:100%;">-
                                    </div>
                                </div>
                            </div>
                            <div class="lineRow flexStretch" style="width:50%;">
                                <div class="cell flexStart">时间</div>
                                <div class="cell flexStart flex_1" style="padding:0 3px;">{{ item.info.signDate || '-' }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import tchtree from '../com/tchTree.vue'

export default {
    components: {
        tchtree
    },
    data() {
        return {
            temphead: "//school.mmteck.cn/static/temp.png",

            dialogshow: false,
            isRevoke: false,
            year_week: "",
            report_year: "",
            week: "",
            weekStart: "",
            weekEnd: "",
            searchForm: {
                report_status: "",
                ktype: "stu_name",
                keyword: "",
                class_id: "",
                month: ""
            },
            EditItem: {
                id: 0,
                group_name: "",
                needpic: "",
                outside: "",
                latesign: ""
            },
            DataList: [],
            ClassList: [],
            page: {
                count: 0,
                current_page: 1,
                per_page: 50,
                total_page: 0
            },
            isEdit: false,
            showKeshiList: false,
            keshiList: [],
            page_keshi: {
                count: 0,
                current_page: 1,
                per_page: 50,
                total_page: 0
            },
            detailInfo: {},
            activeName: 'first',
            evaluationInfo: {},
            jdList: [
                {
                    title: '毕业实习自我鉴定', textareaTitle: '自我鉴定', signTitle: '签名',
                    info: { type: 1, content: '', sign: '', signDate: '' },
                }, {
                    title: '实习小组鉴定', textareaTitle: '小组鉴定', signTitle: '实习组长签名',
                    info: { type: 2, content: '', sign: '', signDate: '' },
                }, {
                    title: '实习单位组织鉴定', textareaTitle: '组织鉴定', signTitle: '负责人签保',
                    info: { type: 3, content: '', sign: '', signDate: '' },
                }
            ],
        };
    },
    filters: {
        handleDate(value) {	// value是输入框的内容，也是要显示的内容
            if (value) {
                return value.substring(0, 10)
            }
        }
    },
    mounted() {
        this.getOrgTree();
        this.getList()
    },
    methods: {
        getOrgTree() {
            this.$http.post("/api/get_tch_cls_data").then(res => {
                res.data.list.map(e => {
                    e.name = e.upname + e.name;
                });
                this.ClassList = res.data.list;
            });
        },

        getList() {
            this.DataList = []
            let params = {
                page: this.page.current_page,
                pagesize: this.page.per_page,
                class_ids: this.searchForm.class_id,
                keyword: this.searchForm.keyword,
            }
            this.$http.post('/api/stu_sx_manual_tab_list', params).then(res => {
                if (res.data.data && res.data.data.length > 0) {
                    res.data.data.map(a => {
                        a.deng = false
                        if (a.department && a.department.indexOf(',') > -1) {
                            a.deng = true
                        }
                    })
                }
                this.DataList = res.data.data;
                this.page = res.data.page;
            })
        },
        handlePageChange(page) {
            this.page.current_page = page;
            this.getList();
        },
        handleSizeChange(val) {
            this.page.current_page = 1;
            this.page.per_page = val;
            this.getList()
        },

        viewInfo(row, type) {
            if (type == 0) {
                this.EditItem = row;
                this.showKeshiList = true;
                this.getKeshiList(row)
            }
            else if (type == 1) {
                this.activeName = 'first'
                console.log(565, row)
                this.dialogshow = true
                this.detailInfo = row
                if (row.evaluation && (row.evaluation != {}) && ((typeof row.evaluation) != "object")) {
                    let evaluationInfo = JSON.parse(row.evaluation)
                    console.log(824, evaluationInfo)
                    this.evaluationInfo = evaluationInfo
                }
                this.getJDList(row)
            }

        },
        getKeshiList(row) {
            let _this = this
            _this.$http.post('/api/stu_sx_manual_list', {
                stu_id: row.stu_id,
                page: _this.page.current_page,
                pagesize: _this.page.per_page,
            }).then(res => {
                if (res.data.data && res.data.data.length > 0) {
                    res.data.data.map(a => {
                        a.content = a.content || ''
                        a.evaluation = a.evaluation || ''
                        if (!a.content || a.content == null || a.content == 'null') {
                            a.content = ''
                        }
                        if (!a.evaluation || a.evaluation == '[object Object]' || a.evaluation == '{}' || a.evaluation == null || a.evaluation == 'null') {
                            a.evaluation = ''
                        }
                    })
                    console.log(566, res.data.data)
                    _this.keshiList = res.data.data
                }
                _this.page_keshi = res.data.page
            })
        },
        getJDList(row) {
            let _this = this
            _this.$http.post('/api/stu_sx_identity_info', {
                stu_id: row.stu_id
            }).then(res => {
                if (res.data && res.data.length > 0) {
                    res.data.map(a => {
                        let info = {
                            type: a.type,
                            content: a.content,
                            sign: a.sign,
                            signDate: a.sign_date,
                            id: a.id
                        }
                        if (a.type == 1) { _this.jdList[0].info = info }
                        if (a.type == 2) { _this.jdList[1].info = info }
                        if (a.type == 3) { _this.jdList[2].info = info }
                    })
                    console.log(880, _this.jdList)
                }
            })
        },
        handlePageChange_keshi(page) {
            this.page_keshi.current_page = page;
            this.getKeshiList();
        },
        handleSizeChange_keshi(val) {
            this.page_keshi.current_page = 1;
            this.page_keshi.per_page = val;
            this.getKeshiList()
        },
        handleClick(tab, event) {
            console.log(613, this.activeName)
        },
        handleEdit(row) {
            this.EditItem = row;
            this.dialogshow = true;
            this.isRevoke = false
            this.isEdit = true
        },
        handleDelete(row) {
            this.$confirm('此操作不可恢复，确认删除？', {
                type: "warning"
            }).then(() => {
                this.$http.post('/api/report_approval_delete', { id: row.id }).then(res => {
                    this.$message.success('删除成功')
                    this.getList()
                })
            })
        },

    }
};
</script>
<style lang="less" type="text/less">
.keshiReportPage {
    .tabContainer {
        .el-tabs__item {
            font-size: 16px;
            font-weight: 700;
        }
    }
}

.el-table .warning-row td {
    background-color: #f8ddc9 !important;
}

.myTooltips {
    max-width: 400px;
}

.keshiReportPage {
    .el-tabs {
        height: unset !important;
    }

    .el-tabs__header {
        margin: 0
    }

    .el-tabs__content {
        display: none;
    }

    .cus_dialogMax {
        .el-dialog__body {
            padding-top: 0
        }
    }
}
</style>
<style lang="less" type="text/less" scoped>
::v-deep .el-tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

::v-deep .el-tabs__content {
    flex: 1;
}

::v-deep .el-tab-pane {
    height: 100%;
}

.spuser {
    display: inline-block;
    border: 1px solid rgb(255, 148, 62);

    padding: 2px;
    margin-right: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    padding: 4px 10px;
}

.spuser img {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
}

.dialog_left {
    .lineRow {
        .cell:first-of-type {
            width: 110px;
            padding-left: 8px;
        }
    }

    .tabThird {
        .lineRow {
            .cell:first-of-type {
                width: 150px;
            }
        }
    }
}
</style>
